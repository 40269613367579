import React from "react";
import { Typography, makeStyles, Container } from "@material-ui/core";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import { Button, Box } from "@material-ui/core";
import pdf from "../media/pdf/Widerrufsformular.pdf";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles({
  root: {
    minHeight: "60vh",
    marginTop: "7rem",
    marginBottom: "2rem",
  },
});

const Widerrufsbelehrung = () => {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <>
      <Navbar />
      <div className={classes.root}>
        <Container maxWidth="md">
          <Typography gutterBottom variant="h2">
            Widerrufsbelehrung
          </Typography>
          <Typography>
            {" "}
            <b>1. Widerrufsbelehrung (Verbraucher)</b>{" "}
          </Typography>
          <Typography>
            <br /> 1.1. Sie haben das Recht, binnen vierzehn Tagen ohne Angabe
            von Gründen diesen Vertrag zu widerrufen.{" "}
          </Typography>
          <Typography>
            <br />
            1.2. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
            Vertragsschlusses.{" "}
          </Typography>
          <Typography>
            <br />
            1.3. Um Ihr Widerrufsrecht auszuüben, müssen Sie M3E (M3E GmbH,
            Immanuelkirchstrasse 12, 10405 Berlin 030 403672121,
            info@m3e-gmbh.com mittels einer eindeutigen Erklärung (z. B. ein mit
            der Post versandter Brief, oder E-Mail) über Ihren Entschluss,
            diesen Vertrag zu widerrufen, informieren. Sie können dafür das
            beigefügte Muster Widerrufsformular verwenden, das jedoch nicht
            vorgeschrieben ist.{" "}
          </Typography>
          <Typography>
            <br />
            1.4. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
            Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
            Widerrufsfrist absenden.
          </Typography>

          <Box display="flex" justifyContent="flex-end" my={3}>
            <Button
              color="primary"
              variant="outlined"
              component="a"
              href={pdf}
              download
            >
              Widerrufsformular herunterladen
            </Button>
          </Box>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Widerrufsbelehrung;
