import React from "react";
import Navbar from "../components/nav/Nav";
import Footer from "../components/nav/Footer";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Link } from "@material-ui/core";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      padding: 0,
    },
  },
  root: {
    margin: "2rem",
    [theme.breakpoints.down("sm")]: {
      margin: "0rem",
      padding: 0,
    },
  },
  ueberschrift: {
    marginBottom: "2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
    },
  },
  lastElement: {
    borderBottom: "1px solid #000",
    marginBottom: "2rem",
  },
  ueberschriften: {
    fontWeight: "bold",
  },
  ueberschriften2: {
    fontWeight: "bold",
    marginBottom: "2rem",
  },
  link: {
    cursor: "pointer",
    marginLeft: "0.5rem",
  },
  absatz: {
    marginBottom: "1rem",
  },
}));

export default function Impressum() {
  const classes = useStyles();
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div className={classes.mainRoot}>
      <Navbar />
      <Grid container className={classes.root}>
        <Grid item xs={1} sm={2} md={2} />
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="h3" component="h1">
                IMPRESSUM
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                component="h2"
                className={classes.ueberschriften}
              >
                Betreiber dieser Seite:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                M3E GmbH - Fullserviceagentur und Beratung für Elektromobilität
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                Immanuelkirchstrasse 12
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="body1" component="p">
                10405 Berlin
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                Registergericht: Amtsgericht Charlottenburg
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschriften2}>
              <Typography
                variant="h5"
                component="h2"
                className={classes.ueberschriften}
              >
                HRB 70275
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="body1" component="p">
                Tel.: 030/403672121
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="body1" component="p">
                Email:
                <Link className={classes.link}>thg@m3e-gmbh.com</Link>
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="body1" component="p">
                Geschäftsführer: Dr. Christian Milan
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="body1" component="p">
                Umsatzsteuer-Idientifikationsnummer gemäß § 27 a
                Umsatzsteuergesetz:
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="body1" component="p">
                USt-IdNr. DE200269605
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.lastElement}>
              <Typography variant="body1" component="p"></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                component="h2"
                className={classes.ueberschriften}
              >
                Verantwortlicher nach § 55 Abs.2 RStV:
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="body1" component="p">
                Dr. Christian Milan
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                M3E GmbH - Fullserviceagentur und Beratung für Elektromobilität
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                Immanuelkirchstrasse 12
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschrift}>
              <Typography variant="body1" component="p">
                10405 Berlin
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.lastElement}>
              <Typography variant="body1" component="p"></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                component="h2"
                className={classes.ueberschriften}
              >
                Bild-/Videonachweise für Website:
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.ueberschriften2}>
              Photo by{" "}
              <a href="https://unsplash.com/@thebrownspy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Spencer Watson
              </a>{" "}
              on{" "}
              <a href="https://unsplash.com/s/photos/trees?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                Unsplash
              </a>
            </Grid>
            <Grid item xs={12} className={classes.absatz}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Footer />
    </div>
  );
}
