import React, { useState } from "react";
import Description from "./Description";
import TableOfContent from "./TableOfContent";
import { CheckBox } from "../fields";
import editIcon from "../../media/icons/editIcon.svg";
import { BASE_URL } from "../../api/config";
import { useSelector } from "react-redux";
import Circling from "../Circling";
import { Success } from "../Alerts";
import store from "../../redux/store";
import { Button } from "@material-ui/core";
import pdfagb from "../../media/pdf/AGB_M3E_GmbH.pdf";

const Quotes = () => {
  const quotes = useSelector((state) => state.quotes.items);

  const [success, setSuccess] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [privacyError, setPrivacyError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [edit, setEdit] = useState(false);
  const [next, setNext] = useState(false);
  const [checkedQuotes, setCheckedQuotes] = useState([]);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const onCheck = (e) => {
    setIsPrivacyChecked(e.target.checked);
  };
  const handleSubmit = () => {
    setNext(true);
    scrollUp();
  };
  const handleEdit = () => {
    setNext(false);
    setEdit(true);
  };
  const submit = async () => {
    if (isPrivacyChecked) {
      setPrivacyError("");
      setIsSubmitted(true);
      const token = store.getState()?.user?.token;

      const resp = await fetch(`${BASE_URL}/thg/quote-years`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Methods": "*",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          quotes: checkedQuotes,
        }),
      });
      const response = await resp.json();

      if (response.success) {
        setSuccess(true);
        scrollUp();
      }

      setIsSubmitted(false);
    } else {
      setPrivacyError("Bitte akzeptieren Sie die AGB!");
    }
  };

  return (
    <div className="text-left">
      <Description />
      {next && !success && (
        <div className="flex justify-end">
          <button
            onClick={handleEdit}
            className="text-white flex py-3 px-4 bg-lightGreen hover:transition hover:ease-in hover:duration-300 hover:bg-darkGreen border border-lightGreen rounded-md uppercase font-[400] text-[1rem] leading-[1.4rem] tracking-wider"
          >
            <span>bearbeiten</span>
            <span className="mr-2">
              <img src={editIcon} className="w-5 h-5" />
            </span>
          </button>
        </div>
      )}
      {success ? (
        <Success />
      ) : (
        <TableOfContent
          checkedQuotes={checkedQuotes}
          setCheckedQuotes={setCheckedQuotes}
          next={next}
          edit={edit}
        />
      )}
      {next
        ? !success && (
            <>
              <div className="mt-[2.5rem]">
                <CheckBox
                  onCheck={onCheck}
                  name="acceptPrivacyPolicy"
                  label="AGB akzeptieren"
                  error={privacyError}
                  value={isPrivacyChecked}
                />
              </div>
              <div className="mb-[2.5rem] ml-5">
                <Button href={pdfagb} download className=" ">
                  AGB herunterladen
                </Button>
              </div>
              <div className="w-[20rem] flex my-[2.5rem]">
                <button
                  onClick={submit}
                  disabled={isSubmitted}
                  className="text-customWhite flex justify-center items-center py-3 w-full bg-lightGreen rounded-md uppercase font-[400] text-[1rem] leading-[1.4rem] tracking-wider hover:shadow-lg"
                >
                  {isSubmitted ? <Circling /> : "Absenden"}
                </button>
              </div>
            </>
          )
        : !success &&
          quotes.length > 0 && (
            <div className="grid grid-cols-1 gap-3 sm:grid-cols-3 mt-[3rem]">
              <div className="flex">
                <button
                  //  onClick={handleSubmit}
                  className="text-customWhite py-3 w-full bg-lightGreen rounded-md uppercase font-[400] text-[1rem] leading-[1.4rem] tracking-wider hover:shadow-lg hover:transition hover:ease-in hover:duration-300 hover:bg-lightestGreen"
                >
                  FORTSETZEN
                </button>
              </div>
            </div>
          )}
    </div>
  );
};

export default Quotes;
